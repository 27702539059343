html {
  font-size: 1vw !important;
  text-decoration-line: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: auto;

  /* Colors ----------------------------- */
  --primary: #1e3c89;
  --primary-light-100: #bcc8e5;
  --light-100: #fafafa;
  --light-200: #f3f2f2;
  --light-300: #ebebeb;
  --light-400: #ededed;
  --light-500: #dddcdc;
  --light-600: #d4d4d4;
  --light-700: #a1a1a1;
  --light-800: #7c7c7c;
  --light-900: #474747;

  --second: #e49c68;
}

/* // Animations ----------------------- */

.expand-both {
  animation: expand-both 1.5s ease both;
}

@keyframes expand-both {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.fade-in-3 {
  -webkit-animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.fade-in-bottom {
  -webkit-animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.fade-in-bottom-1-2 {
  -webkit-animation: fade-in-bottom 1.2s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: fade-in-bottom 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in-left {
  -webkit-animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-left 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fade-in-left {
  0% {
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.rotate-flip {
  -webkit-animation: rotate-flip 0.3s ease-in-out both;
  animation: rotate-flip 0.3s ease-in-out both;
}

@-webkit-keyframes rotate-flip {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes rotate-flip {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

.rotate-flip-back {
  -webkit-animation: rotate-flip-back 0.3s ease-in-out both;
  animation: rotate-flip-back 0.3s ease-in-out both;
}

@-webkit-keyframes rotate-flip-back {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-flip-back {
  0% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.scale-up-ver-bottom {
  -webkit-animation: scale-up-ver-bottom 0.4s
    cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: scale-up-ver-bottom 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.scale-up-ver-bottom-2 {
  -webkit-animation: scale-up-ver-bottom 2s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-ver-bottom 2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-ver-bottom {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scaleY(4);
    transform: scaleY(4);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}
@keyframes scale-up-ver-bottom {
  0% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scaleY(4);
    transform: scaleY(4);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}

.scale-in-ver-top-2s {
  -webkit-animation: scale-in-ver-top 2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: scale-in-ver-top 2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    opacity: 1;
  }
}
